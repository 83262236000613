//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchMebCardList, addMebCard, delMebBindCard, delMebCard } from "@/api/charge";
import { transBindState } from "@/utils/util";
import "@/utils/global.js";
import Pagination from "@/components/Pagination";
export default {
  name: "cardList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        card_no: "",
        status: ""
      },
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      cardDialog: false,
      bindType: _global.bindType,
      dialogForm: {
        card_no: ""
      }
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchMebCardList(data).then(function (response) {
        console.log(response);
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      console.log("添加会员卡");
      addMebCard(this.dialogForm).then(function (res) {
        console.log(res); // 成功之后刷新或
        // this.dataList.unshift('返回的数据')

        _this2.$message({
          type: "success",
          message: "添加会员卡成功"
        });

        _this2.cardDialog = false;

        _this2.getTableData();
      }).catch(function (err) {
        _this2.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleUnbind: function handleUnbind(row) {
      var _this3 = this;

      console.log("unbind");
      console.log(row);
      var data = {
        id: row.id
      };
      delMebBindCard(data).then(function (res) {
        console.log(res);

        _this3.$message({
          type: "success",
          message: "解绑成功"
        }); // 替换原来数据，更新


        var newRow = Object.assign({}, row);
        newRow.status = 1;

        var index = _this3.dataList.indexOf(row);

        _this3.$set(_this3.dataList, index, newRow);
      }).catch(function (err) {
        _this3.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleDel: function handleDel(row) {
      var _this4 = this;

      console.log("结束订单");
      var content = "您确认要该卡号?";
      var title = "删除";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = {
          card_id: row.id
        };
        delMebCard(data).then(function (res) {
          console.log(res);

          _this4.$message({
            type: "success",
            message: "结束订单成功"
          });

          var index = _this4.dataList.indexOf(row);

          _this4.dataList.splice(index, 1);
        }).catch(function (err) {
          _this4.$message({
            type: "error",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    transBindState: transBindState
  }
};